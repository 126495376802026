import React from 'react';
import { Button } from 'components/Button';
import { useBedrockRegistration } from 'store';

import globalStyles from '../styles.module.scss';

/**
 * LoginSuccess component for handling a successful login.
 * @returns {React.ReactElement} The rendered component.
 */
const SuccessScreen = () => {
  const userSignout = useBedrockRegistration((state) => state.userSignout);

  return (
    <>
      <h2 data-testid="account-login-success">Welcome to TODAY!</h2>
      <p className={globalStyles.description}>
        Thank you for joining us,
        <br />
        we&apos;re so happy you&apos;re here!
      </p>
      <Button
        displayType="no-arrow"
        title="Get Started"
        url="/account"
      />
      <Button
        displayType="no-arrow"
        title="Sign Out"
        onClick={() => userSignout()}
      />
    </>
  );
};

export { SuccessScreen };
