import React, { useState } from 'react';
import { useBedrockRegistration } from 'store';
import { Button } from 'components/Button';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import Link from 'components/Link';
import { passwordLoginSchema } from '../validationSchema';
import { HideEye } from '../Icons/HideEye/index';
import { ShowEye } from '../Icons/ShowEye/index';

import styles from './styles.module.scss';
import globalStyles from '../styles.module.scss';


/**
 * LoginScreen component for handling password input and submission.
 * @returns {React.ReactElement} The rendered component.
 */
const LogInScreen = () => {
  const user = useBedrockRegistration((state) => state.user);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const userSignin = useBedrockRegistration((state) => state.userSignin);
  const requestOneTimeCode = useBedrockRegistration((state) => state.requestOneTimeCode);
  const [showPassword, setShowPassword] = useState(false);

  /**
   * Temporarily handles when user clicks on login
   * or one time code link until screens are built out.
   * @param {React.MouseEvent} e
   */
  const handleOTCClick = () => {
    requestOneTimeCode().then(() => {
      // update the screen value based on the response
      setScreen('verifyOTC');
    });
  };

  return (
    <>
      <h2 data-testid="account-login">Log in to your Account</h2>
      <p className={globalStyles.userEmail}>
        {user.email || 'example@example.com'}
      </p>
      <Formik
        initialValues={{ password: '' }}
        validationSchema={passwordLoginSchema}
        onSubmit={(values, { setSubmitting }) => {
          userSignin(values.password).then((result) => {
            setSubmitting(false);
            // update the screen value based on the response
            if (result) setScreen(result);
          });
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          errors,
        }) => (
          <Form onSubmit={handleSubmit} data-testid="account-password" className={globalStyles.form}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="password" className={globalStyles.label}>Please enter your password</label>
            <div className="relative">
              <Field
                name="password"
                type={showPassword ? 'text' : 'password'}
                aria-label="password"
                onChange={handleChange}
                className={errors.password ? globalStyles.errorInput : ''}
              />
              <button
                type="button"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                className={globalStyles.eye}
              >
                {showPassword ? <HideEye /> : <ShowEye />}
              </button>
            </div>

            <ErrorMessage name="password" component="div" className={globalStyles.errorMessage} />
            <Button
              displayType="submit"
              title="Log In"
              disabled={isSubmitting}
            />
            <div className={styles.links}>
              <div><button type="button" onClick={handleOTCClick} className={globalStyles.buttonLink}>Log in with one time code</button></div>
              <div><Link to="https://profile.id.nbcuni.com/forgot-password" target="_blank" className={globalStyles.buttonLink}>Forgot Password</Link></div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export { LogInScreen };
