import React from 'react';
import cx from 'classnames';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import {
  Formik, Form, ErrorMessage, Field,
} from 'formik';
import { Button } from 'components/Button';
import { termsAndConditionsSchema } from '../validationSchema';

import styles from './styles.module.scss';
import globalStyles from '../styles.module.scss';

/**
 * Displays the terms of use checkbox with submit button
 * @returns {React.ReactElement} The rendered component.
 */
const SocialRegistrationScreen = () => {
  const userSocialRegistration = useBedrockRegistration((state) => state.userSocialRegistration);
  const setScreen = useBedrockRegistration((state) => state.setScreen);

  return (
    <>
      <Formik
        initialValues={{ termsAndConditions: false }}
        validationSchema={termsAndConditionsSchema}
        onSubmit={(values, { setSubmitting }) => {
          userSocialRegistration(values.idToken, values.platform).then((result) => {
            setSubmitting(false);
            // update the screen value based on the response
            if (result) setScreen(result);
          });
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          errors,
        }) => (
          <Form
            onSubmit={handleSubmit}
            data-testid="account-social-registration"
            className={globalStyles.form}
          >
            <h2>Before we get started...</h2>
            <p className={cx(styles.dek, globalStyles.description)}>
              Just a quick check to make sure you&apos;re on board with our terms and conditions.
            </p>

            <div className={globalStyles.termsContainer} data-testid="terms-and-conditions">
              <ErrorMessage name="termsAndConditions" component="div" className={globalStyles.errorMessage} />
              <div className={globalStyles.checkboxContainer}>
                <Field
                  name="termsAndConditions"
                  type="checkbox"
                  data-testid="terms-checkbox"
                  className={errors.termsAndConditions ? globalStyles.checkboxError : ''}
                />
                <div>
                  <p>
                    I have read and agree to NBCUniversal&apos;s&nbsp;
                    <a
                      href="https://www.nbc.com/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>
                      &nbsp;and&nbsp;
                    <a
                      href=" https://www.nbcuniversal.com/terms?utm_source=nbc-terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                  <p>
                    I understand that TODAY and NBCUniversal may send me the latest news,
                    promotions and more.
                  </p>
                  <p>
                    NBCUniversal Profile will provide access to content from multiple
                    NBCUniversal brands
                  </p>
                </div>
              </div>
            </div>
            <Button
              displayType="submit"
              title="Create Account"
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export { SocialRegistrationScreen };
