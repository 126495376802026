/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import cx from 'classnames';
import { useRouter } from 'next/router';
import { useBedrockRegistration } from 'store';

// Components
import { ButtonBack } from './Icons/ButtonBack';

// Screens
import { EmailScreen } from './Email';
import { LogInScreen } from './LogIn';
import { SuccessScreen } from './Success';
import { CreateAccountScreen } from './CreateAccount';
import { SocialRegistrationScreen } from './SocialRegistration';
import { VerifyOTCScreen } from './VerifyOneTimeCode';

import styles from './styles.module.scss';

const SHOW_BACK_BUTTON = ['login', 'createAccount', 'verifyOTC', 'loginOTC'];

/**
 * Loading component
 * @param {object} props - The props object.
 * @param {boolean} props.loading - The loading state.
 * @returns {React.ReactElement|null} The rendered component.
 */
function Loading({ loading }) {
  if (!loading) return null;
  return (<div data-testid="loading" className={styles.loader} />);
}

/**
 * Render select to Manually select screen
 * @param {object} props - The props object.
 * @param {LoginScreens} props.screen - The current screen.
 * @param {Function} props.setScreen - The setScreen function.
 * @returns {React.ReactElement|null} The rendered component.
 */
function ManualSelect({ screen, setScreen }) {
  const {
    query: { MANUAL_SCREEN: shouldShowManualScreen },
  } = useRouter();
  /**
   * Manually sets the screen based on the selected value.
   * This is a temporary solution to bypass API calls.
   *
   * @param {object} e - The event object.
   */
  const manuallySetScreen = (e) => {
    setScreen(e.target.value);
  };
  return shouldShowManualScreen === 'true' ? (
    <div className={styles.temp}>
      <p>Manually set screen to bypass API calls</p>
      <select onChange={manuallySetScreen} value={screen}>
        <option value="default">Start</option>
        <option value="login">Login</option>
        <option value="createAccount">Create Account</option>
        <option value="verifyOTC">Verify Email</option>
        <option value="loginOTC">Log in with Code</option>
        <option value="socialRegistration">Social Registration</option>
        <option value="registrationSuccess">Registration Success</option>
      </select>
    </div>
  ) : null;
}

/**
 * handles the screens for the login and registration flow
 *
 * @param {object} props - Component props.
 * @param {LoginLayout} [props.layout] - The layout for the account, can be 'fixed' or 'fluid'.
 * @param {string} [props.entryTitle] - Title for the entry screen.
 * @param {boolean} [props.showSuccessScreen] - Whether to show a screen for registration success.
 * @param {Function} [props.callback] - Callback function to be called when the login/registration process is done
 * and there are no screens to show.
 * @returns {React.ReactElement} The rendered component.
 */
const AccountLoginRegistration = ({
  entryTitle = 'Sign up or log in with your free TODAY account!',
  layout = 'fixed',
  showSuccessScreen = false,
  callback = () => {},
}) => {
  const loading = useBedrockRegistration((state) => state.loading);
  const screen = useBedrockRegistration((state) => state.screen);
  const prevScreen = useBedrockRegistration((state) => state.prevScreen);
  const setScreen = useBedrockRegistration((state) => state.setScreen);

  /**
   * Returns the appropriate form view based on screen name status
   * @returns {React.Component} The screen component.
   */
  const getScreen = () => {
    switch (screen) {
      case 'login':
        return LogInScreen;
      case 'createAccount':
        return CreateAccountScreen;
      case 'socialRegistration':
        return SocialRegistrationScreen;
      case 'verifyOTC':
      case 'loginOTC':
        return VerifyOTCScreen;
      case 'registrationSuccess':
        return showSuccessScreen ? SuccessScreen : null;
      case 'loginSuccess':
        return null;
      default:
        return EmailScreen;
    }
  };

  const Screen = getScreen();

  /**
   * Handles the back button click event
   * @returns {void}
   * */
  const handleBackClick = () => {
    setScreen(prevScreen);
  };

  const shouldShowBackButton = SHOW_BACK_BUTTON.includes(screen);

  // Callback when there are no screens to show and the flow is complete
  useEffect(() => {
    if (!Screen) callback();
  }, [Screen]);

  return Screen ? (
    <>
      <ManualSelect screen={screen} setScreen={setScreen} />
      <div
        className={cx(styles.wrapper, styles[`wrapper__${layout}`])}
        data-testid="screen-wrapper"
      >
        {shouldShowBackButton ? <ButtonBack onClick={handleBackClick} /> : null}
        <div className={styles.screen}>
          <Loading loading={loading} />
          <Screen
            entryTitle={entryTitle}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {... (screen === 'verifyOTC') ? { otcTitle: 'Verify your email address to continue' } : {}}
          />
        </div>
      </div>
    </>
  ) : null;
};

export { AccountLoginRegistration };
