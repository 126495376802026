import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import useSWR from 'swr';
import getConfig from 'next/config';
import dayjs from 'dayjs';

import {
  formatArticleCardsDataUrl,
  fetchLiveBlogCards,
  CARD_QUERY_LIMITS,
} from '../blogUtils';
import { logError } from '../datadog';
import { useFeatureFlagContext } from '../ContextTypes/index';

/**
 * @typedef {import('src/lib/graphqlTypeDefs.js').Card} Card
 */

/**
 * @typedef {object} LiveBlogCardsData
 * @property {Card[]} items
 * @property {Card[]} cards
 * @property {string} id
 * @property {Url} url
 * @property {DateTime} date
 */

/**
 * @typedef {object} LiveBlogCardsState
 * @property {Card[]} latestItems
 * @property {string} [latestPublishedDate]
 * @property {boolean} isLatestValidating
 * @property {Error|object} [latestDataError]
 *
 * @property {Card[]} activeItems
 *
 * @property {() => void} makeLatestActive
 *
 * @property {boolean} hasDeletedItems
 * @property {number} countNewItems
 * @property {Map<string, Card>} newItems
 *
 * @property {() => void} loadMoreCards
 * @property {Error|object} [loadMoreError]
 * @property {boolean} isMoreCardsAvailable
 * @property {boolean} isFetchingMoreCardsToLoad
 */

export const parsedIntervalEnvVar = parseInt(
  getConfig()?.publicRuntimeConfig?.LIVE_BLOG_POLLING_INTERVAL,
  10,
);
const liveBlogPollingInterval = !Number.isNaN(parsedIntervalEnvVar)
  ? parsedIntervalEnvVar
  : 0;

export function getRefreshInterval(latestData) {
  const latestCard = latestData?.cards?.items?.[0];
  const isMostRecentCardOlderThan12Hours = dayjs().diff(dayjs(latestCard?.date?.publishedAt), 'h') >= 12;
  if (isMostRecentCardOlderThan12Hours) return 0;
  return liveBlogPollingInterval;
}

/**
 * Get the difference between the latest data and the active data, Map of new items, whether some
 * items have been deleted in the most recently fetched items, and the count of any new items.
 * exported for testing purposes.
 * @param {LiveBlogCardsData} latestData
 * @param {LiveBlogCardsData} activeData
 * @returns {{
 *   newItems: Map<string, Card>,
 *   hasDeletedItems: boolean,
 *   countNewItems: number,
 * }}
 */
export function getNewAndDeletedDiff(latestData, activeData) {
  if (!latestData?.cards?.items || !activeData?.cards?.items) {
    return {
      newItems: new Map(),
      hasDeletedItems: false,
      countNewItems: 0,
    };
  }

  // using Maps instead of Sets because the object identity of underlying items could change when
  // swr fetches new data, which are then treated as !== by Set.prototype.has
  const latestMap = new Map(latestData?.cards?.items.map((item) => [item.id, item]));
  const activeMap = new Map(activeData?.cards?.items.map((item) => [item.id, item]));

  /**
   * @type {Array<[string, object]>}
   */
  const latestMapEntries = [...latestMap.entries()];

  const firstOldItemPositionInLatestItems = latestMapEntries
    .findIndex(([key]) => activeMap.has(key));

  const latestHasActiveItemsInCommon = firstOldItemPositionInLatestItems >= 0;

  const latestItemsStartingAtFirstOldItem = latestHasActiveItemsInCommon
    ? new Map(latestMapEntries.slice(firstOldItemPositionInLatestItems))
    : new Map();

  const activeEntries = [...activeMap.entries()];

  return {
    newItems: (
      latestHasActiveItemsInCommon
        ? new Map(latestMapEntries.slice(0, firstOldItemPositionInLatestItems))
        : new Map(latestMap)
    ),

    // probably deleted items. We look at the most recent of the active items, find where that is
    // in the latest items, then iterate through to the end of the list to see if any ids don't
    // match index positions
    hasDeletedItems: (
      latestHasActiveItemsInCommon
      && [...latestItemsStartingAtFirstOldItem.entries()]
        .some(([idInLatest], i) => activeEntries[i]?.[0] !== idInLatest)
    ),
    countNewItems: (
      !latestHasActiveItemsInCommon
        ? latestMap.size // if there are no items in common, the entire latest set is new
        : firstOldItemPositionInLatestItems // index of first old item is also a count of new items
    ),
  };
}

/**
 * @param {string} taxonomyPath - the taxonomy path of the live blog
 * @param {object} initialData - the initial data to use for the live blog
 * @returns {LiveBlogCardsState}
 * @example
 * function MyComponent({ taxonomyPath, initialItems, articleId }) {
 *   const {
 *     activeItems,
 *     countNewItems,
 *     makeLatestActive,
 *   } = useLiveBlogCards({ taxonomyPath, initialItems, articleId });
 *   return (
 *     <>
 *       {numberOfNewItems > 0 && (
 *         <button onClick={makeLatestActive}>Show {numberOfNewItems} new items</button>
 *       )}
 *       {activeItems.map((item) => <Card {...item} />)}
 *     </>
 *   );
 * }
 */
export function useLiveBlogCards({
  initialData = { cards: { items: [] } },
  articleId,
}) {
  const launchDarklyFlags = useFeatureFlagContext();
  const firstPageCardLimit = (
    launchDarklyFlags?.['live-blog-card-query-limits']?.firstPage
    ?? CARD_QUERY_LIMITS.FIRST_PAGE
  );
  const nthPageSize = (
    launchDarklyFlags?.['live-blog-card-query-limits']?.loadMore
    ?? CARD_QUERY_LIMITS.LOAD_MORE
  );
  const {
    data: latestData,
    error: latestDataError,
    isValidating: isLatestValidating,
  } = useSWR(
    () => formatArticleCardsDataUrl({
      queryLimit: firstPageCardLimit,
      articleId,
    }),
    fetchLiveBlogCards,
    {
      refreshInterval: getRefreshInterval,
      refreshWhenHidden: true,
      fallbackData: initialData,
    },
  );

  useEffect(() => {
    if (latestDataError) {
      logError(latestDataError);
    }
  }, [latestDataError]);

  const [activeData, setActiveData] = useState(latestData);

  const {
    newItems,
    hasDeletedItems,
    countNewItems,
  } = useMemo(
    () => getNewAndDeletedDiff(latestData, activeData),
    [latestData, activeData],
  );

  const makeLatestActive = useCallback(() => {
    const newActiveDataWithNewCardIndicator = {
      ...latestData,
      cards: {
        items: latestData.cards.items.map((item) => ({
          ...item,
          newActiveItem: newItems.has(item.id),
        })),
      },
    };
    setActiveData(newActiveDataWithNewCardIndicator);
  }, [latestData]);

  const oldestRenderedCard = Array.isArray(activeData?.cards?.items)
  && activeData.cards?.items[activeData.cards?.items.length - 1];
  const oldestRenderedCardPublishedDate = oldestRenderedCard?.date?.publishedAt;
  const loadMoreUrl = oldestRenderedCardPublishedDate
    ? formatArticleCardsDataUrl({
      excludeCard: oldestRenderedCard.id,
      datePublished: oldestRenderedCardPublishedDate,
      queryLimit: nthPageSize,
      articleId,
    })
    : null;

  const {
    data: loadMoreData,
    isLoading: isLoadMoreLoading,
    error: loadMoreError,
  } = useSWR(
    loadMoreUrl,
    fetchLiveBlogCards,
    {
      refreshWhenHidden: true,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (loadMoreError) {
      logError(loadMoreError);
    }
  }, [loadMoreError]);

  const loadMoreCards = useCallback(() => {
    if (loadMoreData?.cards?.items?.length) {
      setActiveData((currentActiveData) => ({
        ...currentActiveData,
        cards: {
          items: [
            ...currentActiveData?.cards?.items,
            ...loadMoreData?.cards?.items,
          ],
        },
      }));
    }
  }, [loadMoreData]);

  useEffect(() => {
    if (hasDeletedItems) {
      makeLatestActive();
    }
  }, [hasDeletedItems]);

  return {
    latestItems: latestData?.cards?.items,
    latestPublishedDate: latestData?.cards?.items?.[0]?.date?.publishedAt,
    isLatestValidating,
    latestDataError,
    activeItems: activeData?.cards?.items || [],
    activeLatestPublishedDate: activeData?.cards?.items?.[0]?.date?.publishedAt,
    makeLatestActive,

    hasDeletedItems,
    countNewItems,
    newItems,

    loadMoreCards,
    loadMoreError,
    isMoreCardsAvailable: Boolean(loadMoreData?.cards?.items?.length),
    isFetchingMoreCardsToLoad: isLoadMoreLoading,
  };
}
