/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button } from 'components/Button';
import { useBedrockRegistration } from 'store';
import { Formik, Field, ErrorMessage } from 'formik';
import { SocialLogin } from 'components/AccountLoginRegistration/SocialLogin';
import { Circles } from 'components/Gate/Circles';

import globalStyles from '../styles.module.scss';
import styles from './styles.module.scss';

import { emailSchema } from '../validationSchema';
/**
 * EmailScreen component for handling email input and submission.
 * @param {object} props - The component props.
 * @param {string} props.entryTitle - The title of the entry screen.
 * @returns {React.ReactElement} The rendered component.
 */
const EmailScreen = ({ entryTitle }) => {
  const setEmail = useBedrockRegistration((state) => state.setEmail);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const { email } = useBedrockRegistration((state) => state.user);
  const searchUserByEmail = useBedrockRegistration((state) => state.searchUserByEmail);

  return (
    <>
      <div className={styles.content}>
        <h2>{entryTitle}</h2>
        <Formik
          initialValues={{ email }}
          validationSchema={emailSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setEmail(values.email);
            const response = await searchUserByEmail(values.email, 'today');
            if (response.success) {
              setScreen('login');
            } else {
              setScreen('createAccount');
            }
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            isSubmitting,
            errors,
          }) => (
            <form onSubmit={handleSubmit} data-testid="account-email" className={globalStyles.form}>
              <label htmlFor="email" className={globalStyles.label}>Please enter your email</label>
              <Field id="email" type="email" name="email" aria-label="email" className={errors.email ? globalStyles.errorInput : ''} />
              <ErrorMessage name="email">{(msg) => <div className={globalStyles.errorMessage}>{msg}</div>}</ErrorMessage>
              <Button
                displayType="submit"
                title="Continue"
                disabled={isSubmitting}
              />
              <div className={styles.separator}><span>or</span></div>
            </form>
          )}
        </Formik>
        <div className={styles.bottom}>
          <SocialLogin />
          <button type="button" className={globalStyles.buttonLink} onClick={() => setScreen('nbcuProfile')}>Powered by NBCUniversal Profile</button>
        </div>
      </div>
      <div className={cx(styles.circleWrapper, styles.circleLeft)}>
        <Circles />
      </div>
      <div className={cx(styles.circleWrapper, styles.circleRight)}>
        <Circles />
      </div>
    </>
  );
};

EmailScreen.propTypes = {
  entryTitle: PropTypes.string.isRequired,
};

export { EmailScreen };
