/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import { Button } from 'components/Button';
import {
  Formik, Field, ErrorMessage,
} from 'formik';
import { oneTimeCodeSchema } from '../validationSchema';

import styles from './styles.module.scss';
import globalStyles from '../styles.module.scss';

/**
 * VerifyOTCScreen component for handling log in with a one time code.
 * @param {object} props - The component props.
 * @param {string} props.userEmail - The email address associated with the user.
 * @param {string} props.otcTitle - The title text for the screen.
 * @returns {React.ReactElement} The rendered component.
 */
const VerifyOTCScreen = ({ userEmail = null, otcTitle = 'Log in with code' }) => {
  const user = useBedrockRegistration((state) => state.user);
  const requestOneTimeCode = useBedrockRegistration((state) => state.requestOneTimeCode);
  const verifyOneTimeCode = useBedrockRegistration((state) => state.verifyOneTimeCode);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const emailToRender = userEmail ?? user.email;
  /**
   * Handles form submission
   * @param {object} values - The component props.
   * @param {object} values.oneTimeCode - The one time code sent to the user.
   */
  const onSubmit = (values) => {
    verifyOneTimeCode(values.oneTimeCode).then((result) => {
      // update the screen value based on the response
      if (result) setScreen(result);
    });
  };

  /**
   * Handles resend code button click
   */
  const resendCode = () => {
    requestOneTimeCode().then((result) => {
      if (result) setScreen(result);
    });
  };

  return (
    <>
      <h2 data-testid="account-verify-email">{otcTitle}</h2>
      <p className={cx(styles.enterCode, globalStyles.description)}>
        Enter the one-time code we sent to
      </p>
      <div className={globalStyles.userEmail} data-testid="verifyOTC-userEmail">{emailToRender || 'example@example.com'}</div>
      <p className={styles.smallTxt}>
        If you couldn&apos;t find our email, please
        <br />
        check your spam folder
      </p>

      <Formik
        initialValues={{ oneTimeCode: '' }}
        validationSchema={oneTimeCodeSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          errors,
        }) => (
          <form
            onSubmit={handleSubmit}
            data-testid="account-password"
            className={globalStyles.form}
          >
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
            <label htmlFor="oneTimeCode" className={globalStyles.label}>What is your code?</label>
            <Field
              name="oneTimeCode"
              type="input"
              placeholder=""
              data-testid="oneTimeCode-input-field"
              className={errors.oneTimeCode ? globalStyles.errorInput : ''}
            />
            <ErrorMessage name="oneTimeCode" component="div" className={globalStyles.errorMessage} />
            <Button
              displayType="submit"
              title="Log in"
              onClick={handleSubmit}
            />
          </form>

        )}
      </Formik>
      <button
        type="button"
        className={globalStyles.buttonLink}
        onClick={resendCode}
        data-testid="resend-code"
      >
        Send code again
      </button>
    </>

  );
};

VerifyOTCScreen.propTypes = {
  userEmail: PropTypes.string,
  otcTitle: PropTypes.string,
};

export { VerifyOTCScreen };
