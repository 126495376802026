import { createAction } from 'redux-actions';

import api from 'lib/api';
import { CARD_QUERY_LIMITS, getProcessedCardData } from 'lib/blogUtils';

// Types
const CARD_LOAD_PENDING = 'liveBlog/CARD_LOAD_PENDING';
const CARD_LOAD_COMPLETE = 'liveBlog/CARD_LOAD_COMPLETE';
const CARD_LOAD_FAILED = 'liveBlog/CARD_LOAD_FAILED';

const CARD_FETCH_PENDING = 'liveBlog/CARD_FETCH_PENDING';
export const CARD_FETCH_COMPLETE = 'liveBlog/CARD_FETCH_COMPLETE';
export const CARD_INSTANCES_FETCH_COMPLETE = 'liveBlog/CARD_INSTANCES_FETCH_COMPLETE';
const CARD_FETCH_FAILED = 'liveBlog/CARD_FETCH_FAILED';
const LOAD_MORE_CARDS_PENDING = 'liveBlog/LOAD_MORE_CARDS_PENDING';
const LOAD_MORE_CARDS_COMPLETE = 'liveBlog/LOAD_MORE_CARDS_COMPLETE';
const LOAD_MORE_CARDS_FAILED = 'liveBlog/LOAD_MORE_CARDS_FAILED';

const UPDATE_ACTIVE_CARDS = 'liveBlog/UPDATE_ACTIVE_CARDS';

// Actions
export const loadCard = (id) => api({
  endpoint: `card/${id}`,
  types: [CARD_LOAD_PENDING, CARD_LOAD_COMPLETE, CARD_LOAD_FAILED],
});

const getCardsFromApi = ({
  excludeCard,
  datePublished,
  page,
  path,
  queryLimit,
}, actionTypes) => {
  let filters = `type:card AND taxonomy:${path}`;
  // CSDD-428: Until we have fully integrated Chat Style blogs this prevents us from displaying
  //           REPLY type Cards as top-level cards with existing Live Blog logic.
  filters += ' AND (subType:"post" OR subType:"text")';
  if (datePublished) {
    // strip +/- timezone portion of timestamp for ISO 8601 compliance
    filters += ` AND datePublished:[* TO ${datePublished.replace(/\+(\d){2}:(\d){2}$/, '')}]`;
  }

  if (excludeCard) {
    filters += ` NOT id:${excludeCard}`;
  }

  let endpoint = `card/search?filters=${filters}&size=${queryLimit}`;
  if (page) {
    endpoint = `${endpoint}&page=${page}`;
  }

  return api({
    endpoint,
    types: actionTypes,
  });
};

const getCardsByPkgInstanceFromApi = ({
  excludeCard,
  page,
  articleId,
  queryLimit,
  shownOnFronts,
}, actionTypes) => {
  const isNebulaBlog = articleId.startsWith('r');
  const nebulaFullArticleId = `rcna${articleId.slice(1)}`;
  const urlQueryComponent = isNebulaBlog ? `url:*${nebulaFullArticleId}*` : `url:*${articleId}*`;

  let filters = `${urlQueryComponent} AND type:card`;
  // CSDD-428: Until we have fully integrated Chat Style blogs this prevents us from displaying
  //           REPLY type Cards as top-level cards with existing Live Blog logic.
  filters += ' AND (subType:"post" OR subType:"text")';
  if (shownOnFronts) {
    filters += ' AND autoCuration:true';
  }

  if (excludeCard) {
    filters += ` NOT id:${excludeCard}`;
  }

  let endpoint = `articlecards/search?articleId=${nebulaFullArticleId}&filters=${filters}&size=${queryLimit}`;
  if (page) {
    endpoint = `${endpoint}&page=${page}`;
  }

  return api({
    endpoint,
    types: actionTypes,
    additionalActionTypeData: { articleId },
  });
};

export const fetchLatestCardsByTaxonomy = (args) => getCardsFromApi(
  args,
  [CARD_FETCH_PENDING, CARD_FETCH_COMPLETE, CARD_FETCH_FAILED],
);


export const fetchLatestCardsForPkg = (args) => getCardsByPkgInstanceFromApi(
  args,
  [CARD_FETCH_PENDING, CARD_INSTANCES_FETCH_COMPLETE, CARD_FETCH_FAILED],
);

export const fetchOlderCards = (args) => getCardsFromApi(
  args,
  [LOAD_MORE_CARDS_PENDING, LOAD_MORE_CARDS_COMPLETE, LOAD_MORE_CARDS_FAILED],
);

export const updateActiveCards = createAction(UPDATE_ACTIVE_CARDS);

export const loadMoreCards = (props) => {
  const { activeItems = [], content: { taxonomy: { path } }, dispatch } = props;

  const oldestCard = activeItems.length && activeItems[activeItems.length - 1];

  if (!oldestCard) return null;

  const datePublished = oldestCard?.date?.publishedAt;
  const oldestCardId = oldestCard?.id;

  return dispatch(fetchOlderCards({
    path, queryLimit: CARD_QUERY_LIMITS.LOAD_MORE, datePublished, excludeCard: oldestCardId,
  }));
};

const getPaginationAndTransformed = (payload) => {
  // Get items from payload
  const pagination = payload?.data?.search?.pagination ?? null;
  const items = payload?.data?.article?.cards?.items ?? [];
  // Run item's components through widget transform
  const transformed = items.map(getProcessedCardData);

  return { transformed, pagination };
};

const hasOtherItemsInCommon = (newItems, oldItems) => {
  const itemsHash = oldItems.reduce((acc, curr) => {
    const itemsRecord = acc;
    itemsRecord[curr.id] = true;

    return itemsRecord;
  }, {});

  return newItems.some(({ id: oldItemId }) => itemsHash[oldItemId]);
};

export const hasMissingItems = (newItems, oldItems) => {
  if (!newItems.length || !oldItems.length) return false;
  // look for first of oldItems
  // in newItems
  const firstOldItemPosition = newItems.findIndex(
    ({ id: newItemId }) => oldItems[0].id === newItemId,
  );

  const firstOldItemMissing = firstOldItemPosition < 0;
  const allOtherItemsMissing = !hasOtherItemsInCommon(newItems, oldItems);

  // if that item is gone, check for other matches
  if (firstOldItemMissing && !allOtherItemsMissing) return true;
  if (firstOldItemMissing && allOtherItemsMissing) return false;

  // if we find first item, look for missing ones
  return !newItems
    .slice(firstOldItemPosition)
    .every((newItem, idx) => newItem.id === oldItems[idx].id);
};

const getNumberOfNewItems = (latestItems, activeItems) => {
  const mostRecentLatestCardId = latestItems[0]?.id;
  const mostRecentActiveCardId = activeItems[0]?.id;

  const isMissingCards = hasMissingItems(latestItems, activeItems);

  if (mostRecentLatestCardId !== mostRecentActiveCardId && !isMissingCards) {
    const newerCardsCount = latestItems.findIndex(
      ({ id: newCardId }) => newCardId === mostRecentActiveCardId,
    );

    return newerCardsCount < 0 ? latestItems.length : newerCardsCount;
  }

  return 0;
};

// Reducer
export const INITIAL_STATE = {
  activeItems: [],
  allTotalItems: null,
  card: null,
  cardLoading: false,
  error: null,
  fetchingLatest: false,
  latestItems: [],
  latestDate: null,
  loadingMoreCards: false,
  numberOfNewItems: 0,
  pagination: null,
  trueTotalItems: null,
  pkgInstances: {},
};

export function liveBlog(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Loading a single card
    case CARD_LOAD_PENDING:
      return {
        ...state,
        cardLoading: true,
      };

    case CARD_LOAD_COMPLETE: {
      const card = action?.payload?.data?.card ?? null;
      return {
        ...state,
        cardLoading: false,
        card: getProcessedCardData(card),
        latestDate: card.date.publishedAt,
      };
    }

    case CARD_LOAD_FAILED:
      return {
        ...state,
        cardLoading: false,
        error: action.payload,
      };

    // Fetching all cards
    case CARD_FETCH_PENDING:
      return {
        ...state,
        fetchingLatest: true,
      };

    case CARD_INSTANCES_FETCH_COMPLETE: {
      const { pagination, transformed } = getPaginationAndTransformed(action.payload);
      const { articleId } = action.payload.additionalActionTypeData;
      const existingPkgInstances = state.pkgInstances;

      const newState = {
        ...state,
        pkgInstances: {
          ...existingPkgInstances,
          [articleId]: {
            fetchingLatest: false,
            latestDate: transformed?.[0]?.date?.publishedAt,
            latestItems: transformed,
            pagination,
          },
        },
      };

      return newState;
    }

    case CARD_FETCH_COMPLETE: {
      const { pagination, transformed } = getPaginationAndTransformed(action.payload);
      const newState = {
        ...state,
        fetchingLatest: false,
        latestDate: transformed?.[0]?.date?.publishedAt,
        latestItems: transformed,
        pagination,
        cardPollingComplete: true,
      };

      if (!state.activeItems.length) {
        newState.activeItems = transformed;
      } else if ((state?.activeItems?.[0]?.id) !== (transformed?.[0]?.id)) {
        newState.numberOfNewItems = getNumberOfNewItems(transformed, state.activeItems);
        // Capture totalItems for use in loading new items
        newState.allTotalItems = pagination.totalItems;
      }

      // Capture totalItems for use in loading more items
      if (!state.trueTotalItems) {
        newState.trueTotalItems = pagination.totalItems;
      }

      const isMissingCards = hasMissingItems(transformed, state.activeItems);
      if (isMissingCards) {
        newState.activeItems = transformed;
      }

      return newState;
    }

    case CARD_FETCH_FAILED:
      return {
        ...state,
        fetchingLatest: false,
        error: action.payload,
      };

    case LOAD_MORE_CARDS_PENDING:
      return {
        ...state,
        loadingMoreCards: true,
      };

    case LOAD_MORE_CARDS_FAILED:
      return {
        ...state,
        loadingMoreCards: false,
        error: true,
      };

    case LOAD_MORE_CARDS_COMPLETE: {
      const { pagination, transformed } = getPaginationAndTransformed(action.payload);

      return {
        ...state,
        activeItems: [
          ...state.activeItems,
          ...transformed,
        ],
        loadingMoreCards: false,
        pagination,
      };
    }

    case UPDATE_ACTIVE_CARDS: {
      // TODOG: test see new posts
      return {
        ...state,
        activeItems: [
          ...state.latestItems.map((item) => ({
            ...item,
            newActiveItem: !(state.activeItems || []).some(({ id }) => id === item.id),
          })),
        ],
        numberOfNewItems: 0,
        trueTotalItems: state.allTotalItems,
      };
    }

    default:
      return state;
  }
}
