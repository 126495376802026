import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

/**
 * ButtonBack component
 * @param {object} props - The component props.
 * @param {Function} props.onClick - The click handler.
 */
export function ButtonBack({ onClick }) {
  return (
    <button
      data-testid="button-back"
      aria-label="Back"
      type="button"
      onClick={onClick}
      className={styles.back}
    >
      <svg fill="none" height="22" viewBox="0 0 13 22" width="13" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="m3.4287 10.8006 9.2834 9.1005-1.4282 1.4-10.711634-10.5005 10.711634-10.500551 1.4282 1.400071z" />
      </svg>
    </button>
  );
}

ButtonBack.propTypes = {
  onClick: PropTypes.func.isRequired,
};
