import React, { useEffect } from 'react';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import { useVertical } from 'lib/Hooks';
import getConfig from 'next/config';

import styles from './styles.module.scss';

const {
  publicRuntimeConfig: {
    TODAY_APPLE_CLIENT_ID,
  },
} = getConfig();

/**
 * Renders a Apple Icon
 */
const AppleIcon = () => (
  <svg fill="none" height="14" viewBox="0 0 12 14" width="12" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m8.65393.795166-.0147-.196777c-.04792.006347-.08985.012166-.12579.017456l-.25588.042273c-.60265.11993-1.13757.430092-1.60478.930502-.50968.5459-.76451 1.12988-.76451 1.75196 0 .04316.00392.10308.01176.17976l.02091.1757c.72749 0 1.36567-.27612 1.91456-.82836.54889-.55225.82333-1.19442.82333-1.926518 0-.040202-.00163-.088867-.0049-.145996zm1.18107 6.480954c0-.96485.4988-1.79003 1.4964-2.47558-.6927-.84213-1.60747-1.26319-2.74446-1.26319-.47918 0-.91698.06983-1.3134.20948l-.24831.08886-.33325.12696c-.21781.0804-.41602.1206-.59463.1206-.1394 0-.32236-.04655-.54888-.13965l-.49661-.19677c-.35286-.14388-.73185-.21582-1.13698-.21582-1.08471 0-1.95594.35546-2.61374 1.0664-.657794.71094-.986687 1.64827-.986687 2.81201 0 1.63771.527099 3.15058 1.581317 4.53858.73185.9691 1.40052 1.4536 2.00604 1.4536.25702 0 .51186-.0487.76452-.146l.32019-.1269.25483-.0889c.35722-.1227.68611-.1841.98669-.1841.31801 0 .68393.0783 1.09777.2349l.20257.0761c.42256.1566.7558.2349.99975.2349.5184 0 1.09124-.3449 1.71857-1.0347.6273-.6897 1.1065-1.5001 1.4375-2.43111-1.2328-.61784-1.8492-1.50439-1.8492-2.65967z" fill="#000" fillRule="evenodd" /></svg>
);


/**
 * Renders a Login with Apple Button
 */
const AppleLoginButton = () => {
  const { vertical } = useVertical();
  const socialLogin = useBedrockRegistration((state) => state.socialLogin);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const setError = useBedrockRegistration((state) => state.setError);
  let appleLoginWrapperButton;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.async = true;

    /**
     * Handles Apple SDK script load
     */
    script.onload = () => {
      const appleLoginWrapper = document.createElement('div');
      appleLoginWrapper.id = 'appleid-signin';
      appleLoginWrapper.style.display = 'none';
      appleLoginWrapper.classList.add('custom-apple-button');

      // Add the wrapper to body
      document.body.appendChild(appleLoginWrapper);

      // Initialize the Apple Sign-In button
      window.AppleID.auth.init({
        clientId: TODAY_APPLE_CLIENT_ID,
        scope: 'name email',
        redirectURI: window.location.href,
        state: 'state',
        nonce: 'nonce',
        usePopup: true,
      });

      // Get the Apple Sign-In button
      appleLoginWrapperButton = appleLoginWrapper.querySelector('div[role=button]');
    };

    /**
     * Handles Apple SDK script loading error
     */
    script.onerror = () => {
      console.error('Failed to load Apple ID SDK script.');
    };

    document.body.appendChild(script);

    document.addEventListener('AppleIDSignInOnSuccess', async (event) => {
      // Handle successful response.
      // eslint-disable-next-line camelcase
      const { id_token } = event.detail.authorization;

      await socialLogin(id_token, vertical, 'apple');
      // Set the screen based on the login status
      setScreen('loginSuccess');
    });
    // Listen for authorization failures.
    document.addEventListener('AppleIDSignInOnFailure', (event) => {
      setError(`*** ${event.detail.error}`);
      // eslint-disable-next-line no-console
      console.log(event.detail.error);
    });
  }, []);

  return (
    <button
      tabIndex={0}
      aria-label="Continue with Apple"
      className={styles.appleLogin}
      id="apple-signin-button"
      type="button"
      onClick={() => appleLoginWrapperButton.click()}
    >
      <AppleIcon />
      Continue with Apple
    </button>
  );
};

export { AppleLoginButton };
