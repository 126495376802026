import React, { useEffect } from 'react';
import { useBedrockRegistration } from 'store/bedrockRegistration';
import { useVertical } from 'lib/Hooks';
import getConfig from 'next/config';

import styles from './styles.module.scss';

const {
  publicRuntimeConfig: {
    TODAY_GOOGLE_CLIENT_ID,
  },
} = getConfig();


/**
 * Renders a Google Icon
 */
const GoogleIcon = () => (
  <svg fill="none" height="14" viewBox="0 0 13 14" width="13" xmlns="http://www.w3.org/2000/svg">
    <g clipRule="evenodd" fillRule="evenodd">
      <path d="m12.8709 7.14742c0-.46091-.0414-.90409-.1182-1.32955h-6.12184v2.51432h3.49814c-.15064.8125-.6086 1.50091-1.297 1.96181v1.6309h2.1007c1.2291-1.1316 1.9382-2.79794 1.9382-4.77748z" fill="#4285f4" />
      <path d="m6.6309 13.5001c1.755 0 3.22637-.582 4.3018-1.5748l-2.10066-1.6309c-.58205.39-1.32659.6205-2.20114.6205-1.69295 0-3.12591-1.14343-3.63704-2.67979h-2.171594v1.68409c1.069544 2.1243 3.267724 3.5809 5.808634 3.5809z" fill="#34a853" />
      <path d="m2.99381 8.23515c-.13-.39001-.20386-.8066-.20386-1.235 0-.42841.07386-.845.20386-1.235v-1.6841h-2.171587c-.440227.8775-.691364 1.87023-.691364 2.9191 0 1.04886.251137 2.04159.691364 2.91909z" fill="#fbbc05" />
      <path d="m6.6309 3.08523c.95432 0 1.81114.32795 2.48477.97204l1.86433-1.86432c-1.12569-1.04886-2.59705-1.69295-4.3491-1.69295-2.54091 0-4.73909 1.45659-5.808634 3.58091l2.171594 1.68409c.51113-1.53636 1.94409-2.67977 3.63704-2.67977z" fill="#ea4335" />
    </g>
  </svg>
);

/**
 * Renders a Login with Google Button
 */
const GoogleLoginButton = () => {
  const { vertical } = useVertical();
  const socialLogin = useBedrockRegistration((state) => state.socialLogin);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  let googleLoginWrapperButton;

  /**
   * Handles click on Login with Google Button
   * @param {object} response
   */
  const handleCredentialResponse = async (response) => {
    const idToken = response.credential;
    const status = await socialLogin(idToken, vertical, 'google');
    // Set the screen based on the login status
    setScreen(status === 'loginSuccess' ? 'loginSuccess' : 'loginError');
  };

  useEffect(() => {
    // Load Google Identity Services SDK script
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;

    /**
     * Handles Google SDK script load
     */
    script.onload = () => {
      // Initialize the Google Sign-In button
      window.google.accounts.id.initialize({
        client_id: TODAY_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });

      const googleLoginWrapper = document.createElement('div');
      // Or you can simple hide it in CSS rule for custom-google-button
      googleLoginWrapper.style.display = 'none';
      googleLoginWrapper.classList.add('custom-google-button');

      // Add the wrapper to body
      document.body.appendChild(googleLoginWrapper);

      // Use GSI javascript api to render the button inside our wrapper
      // You can ignore the properties because this button will not appear
      window.google.accounts.id.renderButton(googleLoginWrapper, {
        type: 'icon',
        width: '200',
      });
      googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]');
    };

    document.body.appendChild(script);
  }, []);

  return (
    <button
      tabIndex={0}
      aria-label="Continue with Google"
      className={styles.googleLogin}
      id="google-signin-button"
      type="button"
      onClick={() => googleLoginWrapperButton.click()}
    >
      <GoogleIcon />
        Continue with Google
    </button>
  );
};

export { GoogleLoginButton };
