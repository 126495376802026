/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import { useBedrockRegistration } from 'store';
import { Button } from 'components/Button';
import set from 'lodash/set';
import { HideEye } from '../Icons/HideEye/index';
import { ShowEye } from '../Icons/ShowEye/index';

import styles from './styles.module.scss';
import globalStyles from '../styles.module.scss';

import {
  PASSWORD_ERROR_TYPES,
  passwordRegistrationSchema,
} from '../validationSchema';


/**
 * PasswordScreen component for handling password creation validation and submission.
 * @returns {React.ReactElement} The rendered component.
 */
const CreateAccountScreen = () => {
  const userRegistration = useBedrockRegistration((state) => state.userRegistration);
  const setScreen = useBedrockRegistration((state) => state.setScreen);
  const { email: tempUserEmail } = useBedrockRegistration((state) => state.user);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [errorsState, setErrors] = useState([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  // temp override until it can correctly be pulled from the store
  const userEmail = tempUserEmail || 'example@example.com';

  /**
   * validatePassword: Formik validation function for password input
   * sets error state in the finally block which is what renders validation errors
   * @param {object} values - The values of the form fields.
   * @returns {object} An object where the keys are the type of error and the values are booleans indicating if the error occurred.
   */
  const validateInput = (values) => {
    // clear any previous errors proir to validation
    setErrors([]);
    // this is what gets used as our errors state if we have errors
    const errorsObj = {};
    try {
      passwordRegistrationSchema.validateSync(values, {
        abortEarly: false,
        userEmail,
      });
    } catch (err) {
      return err.inner.reduce((errs, currentError) => {
        const currErr = set(errs, currentError.path, currentError.message);

        const currentPasswordError = currErr?.password;
        let type;
        if (currentPasswordError) {
          switch (currentPasswordError) {
            case PASSWORD_ERROR_TYPES.LENGTH_12:
              type = 'LENGTH_12';
              break;
            case PASSWORD_ERROR_TYPES.NUMBER:
              type = 'NUMBER';
              break;
            case PASSWORD_ERROR_TYPES.LETTER:
              type = 'LETTER';
              break;
            case PASSWORD_ERROR_TYPES.DISTINCT_FROM_EMAIL:
              type = 'DISTINCT_FROM_EMAIL';
              break;
            case PASSWORD_ERROR_TYPES.CANNOT_USE_PASSWORD:
              type = 'CANNOT_USE_PASSWORD';
              break;
            default:
              break;
          }
        }
        if (currErr?.termsAndConditions) {
          type = 'TERMS_NOT_ACCEPTED';
        }
        errorsObj[type] = true;
        return currErr;
      }, {});
    } finally {
      setErrors(errorsObj);
    }
    return errorsObj;
  };

  /**
   * Filters out the terms and conditions error from the errors object so we
   * have an array with just password errors
   * @param {object} errors - all validation errors from user input
   * @returns {Array}
   */
  const passwordErrors = (errors) => {
    const filteredErrors = Object.keys(errors).filter((key) => key !== 'TERMS_NOT_ACCEPTED');
    return filteredErrors;
  };

  const passwordErrorsLength = passwordErrors(errorsState).length;

  /**
   * Placeholder function until the api is implemented
   * once implemented will send a request to the user registration endpoint with the user's email and password
   * @param {object} values - form values
   * @param {Function} setSubmitting - function to set submitting state
   * @returns {undefined}
   */
  const SubmitToAPI = async (values, setSubmitting) => {
    setSubmitAttempted(true);
    const formErrors = validateInput(values);
    if (formErrors.termsAndConditions
      || formErrors.password
      || !userEmail) {
      setSubmitting(false);
      return;
    }

    const status = await userRegistration({
      email: userEmail, password: values.password, brand: 'today', source: 'default',
    });
    setScreen(status);
  };

  return (
    <>
      <h2 data-testid="account-password-title">Create your account</h2>
      <div className={globalStyles.userEmail} aria-label="user email" data-testid="user-email">{userEmail}</div>
      <Formik
        initialValues={{ password: '', termsAndConditions: false }}
        validationSchema={passwordRegistrationSchema}
        validate={validateInput}
        validateOnBlur
        onSubmit={(values, { setSubmitting }) => {
          SubmitToAPI(values, setSubmitting);
        }}
      >
        {({
          values,
          submitform,
          isSubmitting,
          setSubmitting,
        }) => {
          return (
            <form onSubmit={submitform} data-testid="account-password" className={globalStyles.form}>
              <label
                htmlFor="password"
                data-testid="password-label"
                className={globalStyles.label}
              >
                Please enter your password
              </label>
              <div className="relative">
                <Field
                  id="password"
                  type={passwordFieldType}
                  name="password"
                  aria-label="password"
                  data-testid="password-input-field"
                  className={passwordErrorsLength ? globalStyles.errorInput : ''}
                />
                <button
                  data-testid="password-type-toggle-button"
                  type="button"
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setPasswordFieldType(passwordFieldType === 'password' ? 'text' : 'password');
                  }}
                  className={globalStyles.eye}
                >
                  {passwordFieldType === 'password' ? <ShowEye /> : <HideEye />}
                </button>
              </div>
              <div className={styles.passwordErrorContainer}>
                <div data-testid="validation-criteria" className={styles.passwordErrorContainerWrap}>
                  <p className={errorsState?.LENGTH_12 ? globalStyles.errorMessage : styles.criteria} data-testid="length-error">{PASSWORD_ERROR_TYPES.LENGTH_12}</p>
                  <p className={errorsState?.NUMBER ? globalStyles.errorMessage : styles.criteria} data-testid="number-error">{PASSWORD_ERROR_TYPES.NUMBER}</p>
                  <p className={errorsState?.LETTER ? globalStyles.errorMessage : styles.criteria} data-testid="letter-error">{PASSWORD_ERROR_TYPES.LETTER}</p>
                  {errorsState?.DISTINCT_FROM_EMAIL && (
                    <p className={globalStyles.errorMessage} data-testid="email-error">{PASSWORD_ERROR_TYPES.DISTINCT_FROM_EMAIL}</p>
                  )}
                  {errorsState?.CANNOT_USE_PASSWORD && (
                    <p className={globalStyles.errorMessage} data-testid="password-error">{PASSWORD_ERROR_TYPES.CANNOT_USE_PASSWORD}</p>
                  )}
                </div>
              </div>
              <div className={globalStyles.termsContainer} data-testid="terms-and-conditions">
                {submitAttempted && (
                  <div className={styles.termsErrorContainer} data-testid="checkbox-error">
                    <p className={globalStyles.errorMessage} data-testid="validation-errors">{errorsState?.TERMS_NOT_ACCEPTED && `${PASSWORD_ERROR_TYPES.TERMS_NOT_ACCEPTED}`}</p>
                  </div>
                )}
                <div className={globalStyles.checkboxContainer}>
                  <Field
                    name="termsAndConditions"
                    type="checkbox"
                    data-testid="terms-checkbox"
                    className={submitAttempted && errorsState?.TERMS_NOT_ACCEPTED ? globalStyles.checkboxError : ''}
                  />
                  <div>
                    <p>
                      I have read and agree to NBCUniversal&apos;s&nbsp;
                      <a
                        href="https://www.nbc.com/terms-of-use"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>
                      &nbsp;and&nbsp;
                      <a
                        href=" https://www.nbcuniversal.com/terms?utm_source=nbc-terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                    <p>
                      I understand that TODAY and NBCUniversal may send me the latest news,
                      promotions and more.
                    </p>
                    <p>
                      NBCUniversal Profile will provide access to content from multiple
                      NBCUniversal brands
                    </p>
                  </div>
                </div>
              </div>
              <Button
                displayType="submit"
                title="Create Account"
                disabled={isSubmitting}
                onClick={() => {
                  SubmitToAPI(values, setSubmitting);
                }}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export { CreateAccountScreen };
